import { FC } from "react";

const LockIcon: FC<{
  width?: number;
  height?: number;
  fill?: string;
}> = ({ width = 16, height = 20, fill = "white" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 7C9 6.20435 9.31607 5.44129 9.87868 4.87868C10.4413 4.31607 11.2044 4 12 4C12.7956 4 13.5587 4.31607 14.1213 4.87868C14.6839 5.44129 15 6.20435 15 7V9H9V7ZM7 9V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V9H19C19.2652 9 19.5196 9.10536 19.7071 9.29289C19.8946 9.48043 20 9.73478 20 10V19C20 19.7956 19.6839 20.5587 19.1213 21.1213C18.5587 21.6839 17.7956 22 17 22H7C6.20435 22 5.44129 21.6839 4.87868 21.1213C4.31607 20.5587 4 19.7956 4 19V10C4 9.73478 4.10536 9.48043 4.29289 9.29289C4.48043 9.10536 4.73478 9 5 9H7ZM13 15.5C13 15.1022 13.158 14.7206 13.4393 14.4393C13.7206 14.158 14.1022 14 14.5 14H14.51C14.9078 14 15.2894 14.158 15.5707 14.4393C15.852 14.7206 16.01 15.1022 16.01 15.5V15.51C16.01 15.9078 15.852 16.2894 15.5707 16.5707C15.2894 16.852 14.9078 17.01 14.51 17.01H14.5C14.1022 17.01 13.7206 16.852 13.4393 16.5707C13.158 16.2894 13 15.9078 13 15.51V15.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default LockIcon;
