import { FC } from "react";

const ShieldILockIcon: FC<{
  width?: number;
  height?: number;
  fill?: string;
}> = ({ width = 16, height = 20, fill = "white" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 20C5.68333 19.4167 3.77067 18.0873 2.262 16.012C0.753334 13.9367 -0.000666225 11.6327 4.41696e-07 9.1V3L8 0L16 3V9.1C16 11.6333 15.246 13.9377 13.738 16.013C12.23 18.0883 10.3173 19.4173 8 20ZM6 14H10C10.2833 14 10.521 13.904 10.713 13.712C10.905 13.52 11.0007 13.2827 11 13V10C11 9.71667 10.904 9.47933 10.712 9.288C10.52 9.09667 10.2827 9.00067 10 9V8C10 7.45 9.80433 6.97933 9.413 6.588C9.02167 6.19667 8.55067 6.00067 8 6C7.44933 5.99933 6.97867 6.19533 6.588 6.588C6.19733 6.98067 6.00133 7.45133 6 8V9C5.71667 9 5.47933 9.096 5.288 9.288C5.09667 9.48 5.00067 9.71733 5 10V13C5 13.2833 5.096 13.521 5.288 13.713C5.48 13.905 5.71733 14.0007 6 14ZM7 9V8C7 7.71667 7.096 7.47933 7.288 7.288C7.48 7.09667 7.71733 7.00067 8 7C8.28267 6.99933 8.52033 7.09533 8.713 7.288C8.90567 7.48067 9.00133 7.718 9 8V9H7Z"
        fill={fill}
      />
    </svg>
  );
};

export default ShieldILockIcon;
